<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body">
          <div class="col">
            <a-steps :current="currentStep">
              <a-step />
              <a-step />
              <a-step />
            </a-steps>

            <br />
            <div v-if="currentStep == 0">
              <div class="text-center mb-3">
                <h3>Let's verify your account</h3>
                <small
                  >Please enter the <b>Mobile Number</b> that you've used for
                  registration
                </small>
              </div>
              <base-input label=" Mobile Number" required>
                <a-input
                  v-model="formData.mobile_no"
                  placeholder="Enter Mobile Number"
                />
                <div v-if="$v.formData.mobile_no.$dirty" class="text-danger">
                  <div class="error" v-if="!$v.formData.mobile_no.required">
                    Field is required
                  </div>
                </div>
              </base-input>
            </div>

            <div v-if="currentStep == 1">
              <div class="text-center mb-3">
                <h3>Enter One-Time Password</h3>
                <small
                  >The password was sent to: <b>{{ formData.mobile_no }}</b
                  >. The code will only be valid for 5 minutes.</small
                >
              </div>
              <base-input label=" One-Time Password" required>
                <a-input-search
                  placeholder="Enter OTP Code"
                  v-model="formData.code"
                  @click="resendOtp"
                >
                  <a-button
                    slot="enterButton"
                    :disabled="!canResendOTP"
                    v-if="resendTimer > 0"
                  >
                    Resend in {{ resendTimer }}(s)
                  </a-button>
                  <a-button
                    slot="enterButton"
                    :disabled="!canResendOTP || resendingOtp"
                    v-else
                  >
                    Resend OTP Request
                  </a-button>
                </a-input-search>
                <div v-if="$v.formData.code.$dirty" class="text-danger">
                  <div class="error" v-if="!$v.formData.code.required">
                    Field is required
                  </div>
                </div>
              </base-input>
            </div>

            <div v-if="currentStep == 2">
              <div class="text-center mb-3">
                <h3>Reset Password</h3>
                <small>Please set your new password</small>
              </div>
              <base-input label="Create a password" required>
                <a-input-password
                  placeholder="Enter Password"
                  v-model="formData.password"
                />
                <div v-if="$v.formData.password.$dirty" class="text-danger">
                  <div class="error" v-if="!$v.formData.password.required">
                    Field is required
                  </div>
                </div>
              </base-input>

              <base-input label="Confirm password" required>
                <a-input-password
                  placeholder="Re-enter Password"
                  v-model="formData.confirm_password"
                />
                <div
                  v-if="$v.formData.confirm_password.$dirty"
                  class="text-danger"
                >
                  <div
                    class="error"
                    v-if="!$v.formData.confirm_password.required"
                  >
                    Field is required
                  </div>

                  <div
                    class="error"
                    v-if="!$v.formData.confirm_password.sameAsPassword"
                  >
                    Password does not match
                  </div>
                </div>
              </base-input>
            </div>

            <br />
            <div class="text-center">
              <b-button
                v-if="currentStep == 0"
                class="btn btn-secondary"
                @click="$router.back()"
              >
                Back
              </b-button>

              <b-button v-else @click="currentStep--">
                Back
              </b-button>

              <b-button
                v-if="currentStep == 0"
                variant="primary"
                @click="otpAccountVerification"
                :disabled="nextLoading"
              >
                <span v-if="!nextLoading">Next</span>
                <span v-else><b-spinner small></b-spinner> Processing ...</span>
              </b-button>

              <b-button
                v-else-if="currentStep == 1"
                variant="primary"
                @click="verifyOtp"
                :disabled="nextLoading"
              >
                <span v-if="!nextLoading">Next</span>
                <span v-else><b-spinner small></b-spinner> Processing ...</span>
              </b-button>

              <b-button
                v-else
                variant="primary"
                @click="setNewPassword"
                :disabled="nextLoading"
              >
                <span v-if="!nextLoading">Submit</span>
                <span v-else><b-spinner small></b-spinner> Processing ...</span>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HttpRequest from '../../controllers/httpRequest';
import secureStorage from '../../controllers/secureStorage';
import { required, sameAs } from 'vuelidate/lib/validators';

export default {
  name: 'ForgotPassword',
  props: {
    clpSitePageSettings: Object
  },
  data() {
    return {
      currentStep: 0,
      siteName: null,
      execFunc: true,
      formData: {
        mobile_no: null,
        member_id: null,
        code: null,
        source: null,
        password: '',
        confirm_password: ''
      },
      canResendOTP: false,
      resendingOtp: false,
      resendTimer: 60, // in seconds
      timer: null,
      loggingIn: false,
      hasOtpGenerated: false,
      verifiedOtp: false,
      memberData: null,
      nextLoading: false
    };
  },

  validations: {
    formData: {
      mobile_no: { required },
      code: { required },
      password: { required },
      confirm_password: { required, sameAsPassword: sameAs('password') }
    }
  },
  methods: {
    otpAccountVerification: async function() {
      this.$v.formData.mobile_no.$touch();

      if (!this.hasOtpGenerated && !this.$v.formData.mobile_no.$invalid) {
        this.nextLoading = true;
        const { mobile_no } = this.formData;
        const response = await HttpRequest.postRequest(
          '/api/v2/clp_otp_verifications/reset_password',
          {
            verify: {
              membership_program_id: this.clpSitePageSettings
                .membership_program_id,
              mobile_no,
              site_page_id: this.clpSitePageSettings.id
            }
          }
        );

        if (response.member_id !== undefined) {
          this.formData.member_id = response.member_id;
          this.formData.source = response.source;
          this.formData.mobile_no = response.mobile_no;
          this.hasOtpGenerated = true;

          this.$notification.info({
            message: 'One-Time Password has been sent to your mobile number.'
          });

          this.currentStep++;
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.nextLoading = false;
      } else if (this.hasOtpGenerated && !this.$v.formData.mobile_no.$invalid) {
        this.currentStep++;
      }
    },

    verifyOtp: async function() {
      this.$v.formData.code.$touch();

      if (!this.verifiedOtp && !this.$v.formData.code.$invalid) {
        this.nextLoading = true;
        const { member_id, mobile_no, code, source } = this.formData;

        const response = await HttpRequest.postRequest(
          '/api/v2/clp_otp_verifications/reset_password/verify_otp',
          {
            otp: {
              member_id,
              mobile_no,
              code,
              source
            }
          }
        );

        if (response.message == 'Success') {
          this.$notification.success({
            message: 'OTP has been verified'
          });
          this.verifiedOtp = true;
          this.currentStep++;
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.nextLoading = false;
      } else if (this.verifiedOtp && !this.$v.formData.code.$invalid) {
        this.currentStep++;
      }
    },

    resendOtp: async function() {
      if (!this.verifiedOtp && this.canResendOTP) {
        this.resendingOtp = true;

        const { mobile_no } = this.formData;
        const response = await HttpRequest.postRequest(
          '/api/v2/clp_otp_verifications/reset_password',
          {
            verify: {
              membership_program_id: this.clpSitePageSettings
                .membership_program_id,
              mobile_no,
              site_page_id: this.clpSitePageSettings.id
            }
          }
        );

        if (response.member_id !== undefined) {
          this.formData.member_id = response.member_id;
          this.formData.source = response.source;
          this.formData.mobile_no = response.mobile_no;
          this.hasOtpGenerated = true;

          this.$notification.info({
            message: 'One-Time Password has been sent to your mobile number.'
          });

          this.resendTimer = 60;
          this.canResendOTP = false;
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.resendingOtp = false;
      }
    },

    setNewPassword: async function() {
      this.$v.formData.password.$touch();
      this.$v.formData.confirm_password.$touch();

      if (
        this.verifiedOtp &&
        !this.$v.formData.password.$invalid &&
        !this.$v.formData.confirm_password.$invalid
      ) {
        this.nextLoading = true;
        const {
          member_id,
          mobile_no,
          code,
          source,
          password,
          confirm_password
        } = this.formData;

        const response = await HttpRequest.putRequest(
          '/api/v2/clp_member_accounts/reset_password',
          {
            account: {
              membership_program_id: this.clpSitePageSettings
                .membership_program_id,
              member_id,
              password,
              confirm_password,
              site_page_id: this.clpSitePageSettings.id
            },
            otp: {
              member_id,
              mobile_no,
              code,
              source
            }
          }
        );

        if (response.id !== undefined) {
          this.$notification.success({
            message: 'New password has been set to your account.'
          });

          secureStorage.setItem('clpMemberData', response);

          this.nextLoading = true;

          setTimeout(() => {
            this.$router.push({
              name: 'Rewards',
              params: { siteName: this.clpSitePageSettings.siteName }
            });
          }, 1000);
        } else {
          if (response.error != undefined) {
            this.$notification.error({
              message: response.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
          this.nextLoading = false;
        }
      }
    },

    startResendTimer: function() {
      this.timer = setInterval(() => {
        this.resendTimer--;

        if (this.resendTimer <= 0) {
          this.resendTimer = 0;
          this.canResendOTP = true;
        }
      }, 1000);
    }
  },
  watch: {
    currentStep(currStep) {
      if (currStep == 1) {
        this.startResendTimer();
      } else {
        this.resendTimer = 60;
        this.canResendOTP = false;
        clearInterval(this.timer);
      }
    }
  }
};
</script>

<template>
  <div class="row justify-content-center">
    <div class="col-lg-5 col-md-7">
      <div class="card bg-secondary shadow border-0">
        <div class="card-body" style="padding: 2.5rem">
          <div class="text-center mb-4">
            <h3 class="text-center">Log-In</h3>
          </div>
          <div>
            <div class="row mb--1">
              <div class="col">
                <base-input label="Mobile Number/Email">
                  <a-input
                    placeholder="Enter Mobile Number or Email"
                    v-model="formData.user_account"
                    @keypress.enter="login"
                  />
                  <div
                    v-if="$v.formData.user_account.$dirty"
                    class="text-danger"
                  >
                    <div
                      class="error"
                      v-if="!$v.formData.user_account.required"
                    >
                      Field is required
                    </div>
                  </div>
                </base-input>
              </div>
            </div>
            <div class="row mt--3 mb-2">
              <div class="col">
                <base-input label="Password">
                  <a-input-password
                    placeholder="Enter Password"
                    v-model="formData.password"
                    @keypress.enter="login"
                  />
                  <div v-if="$v.formData.password.$dirty" class="text-danger">
                    <div class="error" v-if="!$v.formData.password.required">
                      Field is required
                    </div>
                  </div>
                  <router-link
                    :to="{
                      name: 'ForgotPassword',
                      params: { siteName: clpSitePageSettings.siteName }
                    }"
                  >
                    <small class="text-right">Forgot password?</small>
                  </router-link>
                </base-input>
              </div>
            </div>

            <div class="row mb-3">
              <div class="col text-center">
                <router-link
                  :to="{
                    name: 'SelfRegistration',
                    params: { siteName: clpSitePageSettings.siteName }
                  }"
                  tag="button"
                  class="btn btn-secondary"
                  v-if="clpSitePageSettings.allow_self_registration"
                >
                  Sign-Up
                </router-link>

                <b-button variant="primary" @click="login" :disabled="loading">
                  <span v-if="!loading">Log-in</span>
                  <span v-else
                    ><b-spinner small></b-spinner> Please wait ...</span
                  >
                </b-button>
              </div>
            </div>

            <div class="row">
              <div class="col text-center">
                <router-link
                  :to="{
                    name: 'AccountVerification',
                    params: { siteName: clpSitePageSettings.siteName }
                  }"
                >
                  <small><u>Signed up in store? Click here!</u></small>
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HttpRequest from '../../controllers/httpRequest';
import secureStorage from '../../controllers/secureStorage';
import { required } from 'vuelidate/lib/validators';

export default {
  name: 'MemberLogin',
  props: {
    clpSitePageSettings: Object
  },
  data() {
    return {
      formData: {
        user_account: null,
        password: null
      },
      siteName: null,
      loading: false
    };
  },
  validations: {
    formData: {
      user_account: { required },
      password: { required }
    }
  },
  methods: {
    login: async function() {
      this.$v.formData.$touch();

      if (!this.$v.formData.$invalid) {
        this.loading = true;
        const { user_account, password } = this.formData;
        const res = await HttpRequest.postRequest(
          '/api/v2/clp_member_accounts/login',
          {
            account: {
              membership_program_id: this.clpSitePageSettings
                .membership_program_id,
              site_page_id: this.clpSitePageSettings.id,
              user_account,
              password
            }
          }
        );

        if (res.access_token !== undefined && res.access_token !== null) {
          secureStorage.setItem('clpSitePageAccessToken', res.access_token);

          this.$notification.success({
            message: 'Success'
          });

          setTimeout(() => {
            console.log('router push');
            this.$router.push({
              name: 'Rewards',
              params: { siteName: this.clpSitePageSettings.siteName }
            });
          }, 1000);
        } else {
          if (res.error != undefined) {
            this.$notification.error({
              message: res.error
            });
          } else {
            this.$notification.error({
              message: 'Something went wrong. Please try again.'
            });
          }
        }

        this.loading = false;
      }
    }
  }
};
</script>

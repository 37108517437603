var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-5 col-md-7"},[_c('div',{staticClass:"card bg-secondary shadow border-0"},[_c('div',{staticClass:"card-body",staticStyle:{"padding":"2.5rem"}},[_vm._m(0),_c('div',[_c('div',{staticClass:"row mb--1"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":"Mobile Number/Email"}},[_c('a-input',{attrs:{"placeholder":"Enter Mobile Number or Email"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}},model:{value:(_vm.formData.user_account),callback:function ($$v) {_vm.$set(_vm.formData, "user_account", $$v)},expression:"formData.user_account"}}),(_vm.$v.formData.user_account.$dirty)?_c('div',{staticClass:"text-danger"},[(!_vm.$v.formData.user_account.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e()],1)],1)]),_c('div',{staticClass:"row mt--3 mb-2"},[_c('div',{staticClass:"col"},[_c('base-input',{attrs:{"label":"Password"}},[_c('a-input-password',{attrs:{"placeholder":"Enter Password"},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}},model:{value:(_vm.formData.password),callback:function ($$v) {_vm.$set(_vm.formData, "password", $$v)},expression:"formData.password"}}),(_vm.$v.formData.password.$dirty)?_c('div',{staticClass:"text-danger"},[(!_vm.$v.formData.password.required)?_c('div',{staticClass:"error"},[_vm._v(" Field is required ")]):_vm._e()]):_vm._e(),_c('router-link',{attrs:{"to":{
                    name: 'ForgotPassword',
                    params: { siteName: _vm.clpSitePageSettings.siteName }
                  }}},[_c('small',{staticClass:"text-right"},[_vm._v("Forgot password?")])])],1)],1)]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col text-center"},[(_vm.clpSitePageSettings.allow_self_registration)?_c('router-link',{staticClass:"btn btn-secondary",attrs:{"to":{
                  name: 'SelfRegistration',
                  params: { siteName: _vm.clpSitePageSettings.siteName }
                },"tag":"button"}},[_vm._v(" Sign-Up ")]):_vm._e(),_c('b-button',{attrs:{"variant":"primary","disabled":_vm.loading},on:{"click":_vm.login}},[(!_vm.loading)?_c('span',[_vm._v("Log-in")]):_c('span',[_c('b-spinner',{attrs:{"small":""}}),_vm._v(" Please wait ...")],1)])],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col text-center"},[_c('router-link',{attrs:{"to":{
                  name: 'AccountVerification',
                  params: { siteName: _vm.clpSitePageSettings.siteName }
                }}},[_c('small',[_c('u',[_vm._v("Signed up in store? Click here!")])])])],1)])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center mb-4"},[_c('h3',{staticClass:"text-center"},[_vm._v("Log-In")])])}]

export { render, staticRenderFns }